import { useTheme } from '@ori-ui/mui';
import { getImageVariants } from '@ori/image-sizes-helper';
import { useMemo } from 'react';

export const useImagesInfo = (imageUrl?: string, smallImageUrl?: string) => {
  const theme = useTheme();

  return useMemo(() => {
    if (!imageUrl) {
      return [];
    }
    const { sm, md, lg, xl } = theme.breakpoints.values;

    return [
      ...getImageVariants({
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- We want to filter-out empty strings as well
        url: smallImageUrl || imageUrl,
        backgroundColor: theme.palette.grey[100],
        imageFormat: 'WebP',
        imageSizeMultiplier: 1.5,
        params: [sm, md].map((v) => ({ width: v, quality: 90 })),
      }),
      ...getImageVariants({
        url: imageUrl,
        backgroundColor: theme.palette.grey[100],
        imageFormat: 'WebP',
        imageSizeMultiplier: 1.5,
        params: [lg, xl, 1920, 3840].map((v) => ({ width: v, quality: 90 })),
      }),
    ].map(({ url, width }, i, arr) => ({
      url,
      media: [`(min-width: ${arr[i - 1]?.width ?? 0}px)`, i === arr.length - 1 ? '' : `(max-width: ${width - 0.01}px)`]
        .filter((v) => v)
        .join(' and '),
    }));
  }, [imageUrl, smallImageUrl, theme.breakpoints.values, theme.palette.grey]);
};
