import type { LoadedLibs, LoadedSpas } from '@ori-appshell/app-loaders';
import { useAppLoaders } from '@ori-appshell/app-loaders';
import { useFeatureToggles } from '@ori-appshell/config';
import { useToken } from '@ori/auth';
import {
  CategoryNavigation,
  FrontpageProvider,
  HeroBannerSection,
  WhatsNewSection,
  translationsNamespace,
  type FrontpageProviderProps,
} from '@ori/frontpage';
import { TranslationsNamespaceProvider } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import { OlapicTypeEnum } from '@ori/olapic-lib';
import { Team, TestIdProvider } from '@ori/testid-generator';
import { useEffect, useMemo } from 'react';
import { FrontPageFeature } from '~/components/FrontPage/FrontPageFeature';
import { APP_NAME } from '~/constants-generated';
import { mainLogger } from '~/logger';

import {
  LazyBeautyVideo,
  LazyBestOffersSection,
  LazyCampaignBannerSection,
  LazyCategoryListing,
  LazyMotivationContent,
  LazyTopBrands,
  LazyVipBenefits,
  LazyNotificationBanner,
  LazyIconicProductsSection,
  LazyBestsellerProductsSection,
} from '../lazy';
import { Inner, StyledOlapicWidget } from './styles';

export type FrontPageContentProps = Pick<FrontpageProviderProps, 'data' | 'hideRating'>;

export function FrontPageContent({ data: fallbackData, hideRating }: FrontPageContentProps) {
  const { setAppLoaders } = useAppLoaders();
  const { isAnonymousUser } = useToken();
  const { isFeatureEnabled } = useFeatureToggles();

  const libs = useMemo(
    () =>
      ({
        basketBubble: { enabled: true },
        basketProvider: { enabled: true },
        backInStockNotification: { enabled: !isAnonymousUser },
        favoritesProvider: { enabled: !isAnonymousUser },
        favoritesSubmenu: { enabled: !isAnonymousUser },
        loyaltyDialog: { enabled: !isAnonymousUser },
        miniShoppingBag: { enabled: true },
        novageSkincareAdvisor: { enabled: true },
        productInfoDialog: { enabled: true },
        subscriptionWizard: { enabled: true },
      }) satisfies LoadedLibs,
    [isAnonymousUser],
  );

  const spas = useMemo(
    () =>
      ({
        logoutConfirmDialog: { enabled: !isAnonymousUser },
        userSessionProvider: { enabled: !isAnonymousUser },
      }) satisfies LoadedSpas,
    [isAnonymousUser],
  );

  useEffect(() => {
    setAppLoaders({ libs, spas });
  }, [setAppLoaders, spas, libs]);

  return (
    <TestIdProvider
      team={Team.Presentation}
      project={APP_NAME}
    >
      <LoggerProvider logger={mainLogger}>
        <TranslationsNamespaceProvider namespace={translationsNamespace}>
          <FrontpageProvider
            data={fallbackData}
            hideRating={hideRating}
          >
            <ErrorBoundary areaName="notification-banner">
              <LazyNotificationBanner />
            </ErrorBoundary>
            <ErrorBoundary areaName="hero-banner">
              <TestIdProvider
                team={Team.Presentation}
                project="hero-banner"
              >
                <HeroBannerSection />
              </TestIdProvider>
            </ErrorBoundary>
            <ErrorBoundary areaName="category-navigation">
              <TestIdProvider
                team={Team.Presentation}
                project="category-navigation"
              >
                <CategoryNavigation />
              </TestIdProvider>
            </ErrorBoundary>
            <Inner>
              <ErrorBoundary areaName="whats-new">
                <TestIdProvider
                  team={Team.Presentation}
                  project="whats-new"
                >
                  <WhatsNewSection />
                </TestIdProvider>
              </ErrorBoundary>
              <ErrorBoundary areaName="best-offers">
                <LazyBestOffersSection />
              </ErrorBoundary>
              {isFeatureEnabled(FrontPageFeature.IconicProductsVisible) ? (
                <ErrorBoundary areaName="iconic-products">
                  <LazyIconicProductsSection />
                </ErrorBoundary>
              ) : null}
              {isFeatureEnabled(FrontPageFeature.BestsellerProductsVisible) ? (
                <ErrorBoundary areaName="best-seller">
                  <LazyBestsellerProductsSection />
                </ErrorBoundary>
              ) : null}
              <ErrorBoundary areaName="campaign-banner">
                <LazyCampaignBannerSection />
              </ErrorBoundary>
              <ErrorBoundary areaName="olapic">
                <StyledOlapicWidget type={OlapicTypeEnum.FrontPage} />
              </ErrorBoundary>
              <ErrorBoundary areaName="category-listing">
                <LazyCategoryListing />
              </ErrorBoundary>
              <ErrorBoundary areaName="vip-benefits">
                <LazyVipBenefits />
              </ErrorBoundary>
              <ErrorBoundary areaName="motivation-content">
                <LazyMotivationContent />
              </ErrorBoundary>
              <ErrorBoundary areaName="top-brands">
                <LazyTopBrands />
              </ErrorBoundary>
              <ErrorBoundary areaName="beauty-video">
                <LazyBeautyVideo />
              </ErrorBoundary>
            </Inner>
          </FrontpageProvider>
        </TranslationsNamespaceProvider>
      </LoggerProvider>
    </TestIdProvider>
  );
}
