import { Clear } from '@ori-ui/icons';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useTranslations } from '../../hooks';
import { NOTIFICATION_BANNER_CLOSE_ICON } from './constants';
import { CloseIconButton } from './styles';
import type { NotificationBannerProps } from './types';

export type NotificationBannerCloseButtonProps = Pick<NotificationBannerProps, 'onCloseClick'>;
export const NotificationBannerCloseButton: FC<NotificationBannerCloseButtonProps> = ({ onCloseClick }) => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();

  return (
    <CloseIconButton
      aria-label={translations.close}
      data-testid={getTestId(NOTIFICATION_BANNER_CLOSE_ICON)}
      variant="outlined"
      color="default"
      size="small"
      onClick={onCloseClick}
    >
      <Clear fontSize="inherit" />
    </CloseIconButton>
  );
};
