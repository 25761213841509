import { type PositionData } from '@ori/analytics-data';
import useTestId from '@ori/testid-generator';
import { memo, type FC } from 'react';

import { type HeroBannerFragment } from '../../models';
import { useImagesInfo } from '../HeroBannerMetadata/hooks';
import { CAROUSEL_IMAGE, CAROUSEL_IMAGE_ROOT, CAROUSEL_IMAGE_SKELETON } from './constants';
import { Image } from './styles';

export type CarouselImageProps = Pick<
  HeroBannerFragment & PositionData,
  'headline' | 'imageUrl' | 'smallImageUrl' | 'position'
>;

export const CarouselImage: FC<CarouselImageProps> = memo(({ headline, imageUrl, smallImageUrl, position }) => {
  const { getTestId } = useTestId();

  const images = useImagesInfo(imageUrl ?? undefined, smallImageUrl ?? undefined);

  if (!imageUrl || !images[0]?.url) {
    return (
      <Image
        data-testid={getTestId(CAROUSEL_IMAGE_SKELETON)}
        as="div"
      />
    );
  }

  return (
    <picture data-testid={getTestId(CAROUSEL_IMAGE_ROOT)}>
      {images.map(({ url, media }) => (
        <source
          key={url}
          srcSet={url}
          media={media}
          type="image/webp"
        />
      ))}
      <Image
        data-testid={getTestId(CAROUSEL_IMAGE)}
        alt={headline ?? ''}
        src={images[0].url}
        loading={position === 0 ? 'eager' : 'lazy'}
        decoding={position === 0 ? 'auto' : 'async'}
        fetchPriority={position === 0 ? 'high' : 'low'}
      />
    </picture>
  );
});
