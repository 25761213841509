import { gql } from '@ori/fetching';
import { PRODUCT_WITH_SHADES_FRAGMENT } from '@ori/product-box';
import { VIP_CUSTOMER_BANNER_FRAGMENT } from '@ori/vip-customer-banner';

import { CATEGORY_BANNERS_FRAGMENT } from './CategoryListing';
import { BEAUTY_VIDEO_FRAGMENT } from './beautyVideo';
import { BENEFIT_BANNER_FRAGMENT } from './benefitBannerQuery';
import { BEST_OFFERS_BANNERS_FRAGMENT } from './bestOffers';
import { BESTSELLER_PRODUCTS_FRAGMENT } from './bestSellerProducts';
import { CAMPAIGN_BANNERS_FRAGMENT } from './campaignBanner';
import { CATEGORY_NAVIGATION_FRAGMENT } from './categoryNavigation';
import { HERO_BANNER_FRAGMENT } from './heroBannerQuery';
import { ICONIC_PRODUCTS_FRAGMENT } from './iconicProducts';
import { MOTIVATION_CONTENT_FRAGMENT } from './motivationContent';
import { NOTIFICATION_BANNER_FRAGMENT } from './notificationBannerQuery';
import { PRODUCT_PROMOTION_BANNER_FRAGMENT } from './productPromotionBanner';
import { TOP_BRANDS_FRAGMENT } from './topBrands';
import { VIP_BENEFITS_FRAGMENT } from './vipBenefits';
import { WHATS_NEW_BANNERS_FRAGMENT } from './whatsNew';

export const frontPageQuery = gql`
  query GetFrontPage($validityDate: Date) {
    application {
      frontpage {
        ...MotivationContent
        ...TopBrands
        ...BeautyVideo
        ...VipBenefits
        ...CategoryNavigation
        ...BenefitBanner
        ...NotificationBanner
        ...BestsellerProducts
        ...IconicProducts
        digitalPromotions(validityDate: $validityDate, channel: Website) {
          heroBanners {
            ...HeroBanner
          }
          bestOffersBanners {
            ...BestOffersBanners
          }
          bestOffersProducts {
            ...ProductWithShades
          }
          whatsNewBanners {
            ...WhatsNewBanners
          }
          whatsNewProducts {
            ...ProductWithShades
          }
          categoryBanners {
            ...CategoryBanners
          }
          categoryProducts {
            ...ProductWithShades
          }
          campaignBanners {
            ...CampaignBanners
          }
        }
      }
    }
    ...VipCustomerBanner
  }
  ${MOTIVATION_CONTENT_FRAGMENT}
  ${TOP_BRANDS_FRAGMENT}
  ${BEAUTY_VIDEO_FRAGMENT}
  ${VIP_BENEFITS_FRAGMENT}
  ${CATEGORY_NAVIGATION_FRAGMENT}
  ${BENEFIT_BANNER_FRAGMENT}
  ${HERO_BANNER_FRAGMENT}
  ${BEST_OFFERS_BANNERS_FRAGMENT}
  ${WHATS_NEW_BANNERS_FRAGMENT}
  ${CATEGORY_BANNERS_FRAGMENT}
  ${CAMPAIGN_BANNERS_FRAGMENT}
  ${VIP_CUSTOMER_BANNER_FRAGMENT}
  ${PRODUCT_WITH_SHADES_FRAGMENT}
  ${NOTIFICATION_BANNER_FRAGMENT}
  ${BESTSELLER_PRODUCTS_FRAGMENT}
  ${ICONIC_PRODUCTS_FRAGMENT}
  ${PRODUCT_PROMOTION_BANNER_FRAGMENT}
`;
