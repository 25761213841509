import { IconButton } from '@ori-ui/components';
import { Link, styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 101,
  background: theme.palette.default.main,
  color: theme.palette.common.white,
}));

export const LinkArea = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  '&:hover, &:focus, &:active': {
    color: theme.palette.common.white,
  },
}));

export const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  '&:hover, &:focus, &:active': {
    borderColor: theme.palette.common.white,
  },
}));
