// istanbul ignore file -- Not able to test content of next/head
import Head from 'next/head';
import { type FC } from 'react';

import { useFrontpage } from '../../contexts';
import { useImagesInfo } from './hooks';

export const HeroBannerMetadata: FC = () => {
  const { heroBanners } = useFrontpage();
  const { smallImageUrl, imageUrl } = heroBanners[0] ?? {};

  const imageProps = useImagesInfo(imageUrl ?? undefined, smallImageUrl ?? undefined);

  return (
    <Head>
      {imageProps.map(({ url, media }) => (
        <link
          key={url}
          rel="preload"
          as="image"
          href={url}
          media={media}
          fetchPriority="high"
        />
      ))}
    </Head>
  );
};
