import { Placement } from '@ori/analytics-data';
import { useAreFavoritesEnabled, useIsMobile } from '@ori/presentation-hooks';
import type { Buttons } from '@ori/product-box';
import { ProductBox } from '@ori/product-box';
import useTestId from '@ori/testid-generator';
import { useCallback } from 'react';

import { PRODUCTS_BOX, PRODUCTS_ROOT } from '../../../../constants';
import { useTranslations } from '../../../../hooks/useTranslations';
import { Root } from './styles';
import type { ProductsProps } from './types';

export const Products = ({
  productBoxes,
  maxProductsToDisplay,
  maxProductsToDisplayMobile,
  hideRating,
  customerId,
}: ProductsProps) => {
  const {
    translations: { favorites, quickBuy },
  } = useTranslations();
  const favoritesEnabled = useAreFavoritesEnabled({ customerId });

  const getButtonsWithFavoritesOption = useCallback(
    (buttons?: Buttons | null): Buttons => ({
      ...buttons,
      favorites: { ...buttons?.favorites, visible: favoritesEnabled },
    }),
    [favoritesEnabled],
  );

  const { getTestId } = useTestId();
  const isMobile = useIsMobile();

  let numberOfProducts = maxProductsToDisplay ?? productBoxes.length;

  if (isMobile) {
    numberOfProducts = maxProductsToDisplayMobile ?? productBoxes.length;
  }

  return (
    <Root
      data-testid={getTestId(PRODUCTS_ROOT)}
      productCount={numberOfProducts}
    >
      {productBoxes.slice(0, numberOfProducts).map((productProps, index) => (
        <li
          key={productProps.code}
          data-testid={PRODUCTS_BOX}
        >
          <ProductBox
            {...productProps}
            analyticsData={{ placement: Placement.NewProducts, position: index }}
            hideRating={hideRating}
            translations={{ favorites, quickBuy }}
            buttons={getButtonsWithFavoritesOption(productProps.buttons ?? undefined)}
          />
        </li>
      ))}
    </Root>
  );
};
