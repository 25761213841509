/* eslint-disable @typescript-eslint/naming-convention -- based on component names */
export const lazyWrappersTestIds = {
  'best-offers': 'best-offers-lazy-wrapper',
  'beauty-video': 'beauty-video-app-lazy-wrapper',
  'campaign-banner': 'campaign-banner-lazy-wrapper',
  'category-listing': 'category-listing-lazy-wrapper',
  'lazy-footer': 'footer-lazy-wrapper',
  'motivation-content': 'motivation-content-lazy-wrapper',
  'top-brands': 'top-brands-lazy-wrapper',
  'vip-benefits': 'vip-benefits-lazy-wrapper',
  'best-seller': 'best-seller-lazy-wrapper',
  'iconic-products': 'iconic-products-lazy-wrapper',
};
